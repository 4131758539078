import React, { useState, useEffect, useContext } from "react"
import Client from "shopify-buy"
const SHOPIFY_CHECKOUT_STORAGE_KEY = "shopify_checkout_id"

const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_API_KEY,
  domain: `${process.env.GATSBY_SHOP_NAME}.myshopify.com`,
})

const initialStoreState = {
  client,
  updating: false,
  checkout: { lineItems: [] },
}

const StoreContext = React.createContext({
  store: initialStoreState,
  setStore: () => null,
})

const createNewCheckout = (store) => store.checkout.create()
const fetchCheckout = async (client, id) => await client.checkout.fetch(id);

const setCheckoutInState = (checkout, setStore) => {
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, checkout.id)
  }

  setStore(state => {
    return { ...state, checkout }
  })
}

const StoreContextProvider = ({ children }) => {
  const [store, setStore] = useState(initialStoreState)

  useEffect(() => {
    const initializeCheckout = async () => {
      const isBrowser = typeof window !== "undefined"
      const existingCheckoutId = isBrowser
        ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
        : null

      if (existingCheckoutId) {
        try {
          const checkout = await fetchCheckout(client, existingCheckoutId)
          if (!checkout.completedAt) {
            setCheckoutInState(checkout, setStore)
            return
          }
        } catch (e) {
          localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, null)
        }
      }

      const newCheckout = await createNewCheckout(client)
      setCheckoutInState(newCheckout, setStore)
    }

    initializeCheckout()
  }, [])

  return (
    <StoreContext.Provider
      value={{
        store,
        setStore,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

const useStore = () => {
  const { store } = useContext(StoreContext)
  return store
}

const useCartCount = () => {
  const { store } = useContext(StoreContext)
  const { checkout } = store
  const count = checkout.lineItems.reduce((acc, item) => item.quantity + acc, 0)
  return count
}

const useCartTotals = () => {
  const { store: { checkout }} = useContext(StoreContext)

  const tax = checkout.totalTaxV2
    ? `$${Number(checkout.totalTaxV2.amount).toFixed(2)}`
    : "-"
  const total = checkout.totalPriceV2
    ? `$${Number(checkout.totalPriceV2.amount).toFixed(2)}`
    : "-"

  return {
    tax,
    total,
  }
}

const useCartItems = () => {
  const {store: { checkout }} = useContext(StoreContext)
  return checkout.lineItems
}

const useAddItemToCart = () => {
  const {
    store: { checkout, client },
    setStore,
  } = useContext(StoreContext)

  const addItemToCart = async (variantId, quantity) => {
    if (!Number(quantity)) {
      console.error("Quantity is required.")
      return
    }

    setStore(state => {
      return { ...state, updating: true }
    })

    const checkoutId = checkout.id
    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }]

    const newCheckout = await client.checkout.addLineItems(
      checkoutId,
      lineItemsToAdd
    )

    return setStore(state => {
      return { ...state, checkout: newCheckout, updating: false }
    })
  }

  return addItemToCart
}

const useUpdateItem = () => {
  const {
    store: { client, checkout },
    setStore,
  } = useContext(StoreContext)

  const updateItem = async (id, quantity) => {
    if (!Number(quantity)) {
      console.error("Quantity is required.")
      return
    }

    setStore(state => {
      return { ...state, updating: true }
    })

    const checkoutId = checkout.id
    const lineItemsToAdd = [{ id, quantity: parseInt(quantity, 10) }]

    const updatedCheckout = await client.checkout.updateLineItems(
      checkoutId,
      lineItemsToAdd
    )

    return setStore(state => {
      return { ...state, checkout: updatedCheckout, updating: false }
    })
  }

  return updateItem
}

const useRemoveItemFromCart = () => {
  const {
    store: { client, checkout },
    setStore,
  } = useContext(StoreContext)

  const removeItemFromCart = async (itemId) => {
    setStore(state => {
      return { ...state, updating: true }
    })

    const newCheckout = await client.checkout.removeLineItems(checkout.id, [
      itemId,
    ])

    setStore(state => {
      return { ...state, updating: false, checkout: newCheckout }
    })
  }

  return removeItemFromCart
}

const useClearCart = () => {
  const { store: { client, checkout }, setStore } = useContext(StoreContext)
  
  const clearCart = async () => {
    const ids = checkout.lineItems.map(item => item.id)
    const newCheckout = await client.checkout.removeLineItems(checkout.id, ids)
    setStore(state => ({ ...state, checkout: newCheckout }))
  };

  return clearCart;
}

const useCheckout = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)

  return () => {
    window.open(checkout.webUrl)
  }
}

export {
  StoreContextProvider,
  useAddItemToCart,
  useUpdateItem,
  useStore,
  useCartCount,
  useCartItems,
  useCartTotals,
  useRemoveItemFromCart,
  useClearCart,
  useCheckout,
}